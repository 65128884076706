import { Component, OnInit } from '@angular/core';
import { ConstantsService } from '../../services/constants.service';
import { HttpServiceService } from '../../services/http-service.service';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';

@Component({
  selector: 'app-patient-tracker',
  templateUrl: './patient-tracker.component.html',
  styleUrls: ['./patient-tracker.component.scss']
})
export class PatientTrackerComponent implements OnInit {

  loading = true;

  title = 'patientTracker';
  patientName = '';

  allDetailsArr = [];
  groups = [];
  pid = '';
  clickedRow = -1;
  clickedCol = -1;

  constructor(
    private constants: ConstantsService,
    private http: HttpServiceService,
    private route: ActivatedRoute,
    
  ) { 
    this.route.params.subscribe( params => {
      this.pid = params.id;
      // this.pid = 'test2';
    });
  }

  ngOnInit() {
    this.getDetails();
  }

  getDetails(testAppt = this.pid) {
    this.loading = true;
    const params = {
      apptId: testAppt,
    };
    this.http.getData2(this.constants.patientTrackerUrl, params ).then((res:any) => {
      this.loading = false;
      this.allDetailsArr = res.result.patientEvents.map(x => {
        return {
          eventTime: moment(x.eventTime).format(' h:mm a, DD-MMM-YY'),
          eventName: x.eventName.replaceAll('_', ' ').replace(/\w\S*/g, function(upper){return upper.charAt(0).toUpperCase() + upper.substr(1).toLowerCase();}),
          data: x.data,
          dataNames: Object.keys(x.data ? x.data : {}).filter(ele => ele != 'apptId')
        };
      });
      this.patientName = res.result.patientName.replace(/\w\S*/g, function(upper){return upper.charAt(0).toUpperCase() + upper.substr(1).toLowerCase();});
      let parent = this.allDetailsArr.concat([])

      for (let i=0; i<=this.allDetailsArr.length; i++) {
        let items = parent.splice(0, 6);
        if(i%2 !== 0) items = items.reverse();
        if(items.length) this.groups.push(items);
      }
     })
      .catch((err) => {
      });
  }

  showBody (i,j){
    if(this.clickedCol === j && this.clickedRow === i) {this.clickedRow = this.clickedCol = -1; return;}
    this.clickedRow = i;
    this.clickedCol = j;
  }
}
