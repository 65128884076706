import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ConstantsService {

  otSchedularUrl = environment.url + 'getAppointment';
  docCityCategory = environment.url + 'appointmentDropDown';
  patientTrackerUrl = environment.patientTrackerUrl + 'getPatientEvents';

  /* otSchedularUrl = environment.stagingUrl + 'getAppointment';
  docCityCategory = environment.stagingUrl + 'appointmentDropDown';
  patientTrackerUrl = environment.patientTrackerUrl + 'getPatientEvents'; */

  constructor() { }
}
