import { Component, OnInit, HostListener } from '@angular/core';
import { ConstantsService } from 'src/services/constants.service';
import { HttpServiceService } from 'src/services/http-service.service';
import { Router } from '@angular/router';

import { Moment } from 'moment';
import * as moment from 'moment';
import * as XLSX from 'xlsx';

import { faFileExcel } from '@fortawesome/free-regular-svg-icons'

export interface OTData {
  city: string;
  doctorName: string;
  category: any;
  appointmentDate: any;
  pageNo: number;
  pageSize: number;
}
@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})

export class LayoutComponent implements OnInit {

  loading = true;
  isDropdownOpen = false;

  masterDetailArr = [];
  allDetailsArr = [];
  firstPageIcon = true;
  lastPageIcon = true;
  pageNumber = 1;
  pageSize = 10000000;
  OTMaxCount: any;
  maxPageNumber;
  totalCount;

  selectedCities: string[];
  selectedDoctors: string[];
  selectedCategories: string[];

  selectedDate: {startDate: Moment, endDate: Moment};
  appointmentDate;
  allAppointmentDates = [];
  formattedDates;
  finalizedDates;

  currentDate = moment().format('DD-MM-YYYY');
  dropdownOptions = ['On-Time', 'Delayed', 'NA', 'No Info Received', 'Yet to Start']
  selectedOption = {};
  dropdownSettings = {
    singleSelection: true,
  }
  dropdownSettingsForFilter = {
    singleSelection: false,
  }
  tableFilteredObject = {
    appointmentCreationStatus: false,
    otRoomConfirmation: false,
    staffAvailability: false,
    patientArrival: false,
    admissionToRoom: false,
    preAuthReqRecv: false,
    preAuthSent: false,
    investigationReport: false,
    otStart: false,
    otEnd: false,
    otNotesUpload: false,
    pds: false,
    billBreakUp: false,
    fds: false,
    finalBill: false,
  };

  selectAllCheckBox;
  cityCheckBox;
  doctorNameCheckBox;
  categoryCheckBox;
  dateCheckBox;

  data;
  city;
  category;
  allCategories;
  docName;
  docNameArr;
  allCitiesArr;
  currentFilters:any = {};
  filteredArr;
  fileName= 'ExcelSheet.xlsx';

  faFileExcel = faFileExcel;  


  constructor(
    private constants: ConstantsService,
    private http: HttpServiceService,
    private router: Router 
  ) {  }

  ngOnInit() {
    this.getDetails();   
    this.getDocCityCategoryData();
  }

  onSearch(id:string) {
    this.router.navigate(['ptracker', id]); (1)
  }

  closeDialog() {
    this.isDropdownOpen = false;
  }

  openDropdown() {
    this.isDropdownOpen = !this.isDropdownOpen
  }

  changeSelectAll() {
    this.selectAllCheckBox = false;
  }

  handleSelectAll(e) {
    this.cityCheckBox = e.checked;
    this.doctorNameCheckBox = e.checked;
    this.categoryCheckBox = e.checked;
    this.dateCheckBox = e.checked;
  }

  filteredArray = this.allDetailsArr;
  onTableFilterSelect(e, key) {
    this.tableFilteredObject[key] = e;
    this.filteredArray= this.allDetailsArr;

    Object.keys(this.tableFilteredObject).map(element => {
      if(this.tableFilteredObject[element]) this.filteredArray = this.filteredArray.filter(ele => ele[element].includes(this.tableFilteredObject[element]) )
    })
  }
  onItemDeSelect(e, key) {
    this.tableFilteredObject[key] = false;
    this.filteredArray= this.allDetailsArr;
    Object.keys(this.tableFilteredObject).map(element => {
      if(this.tableFilteredObject[element]) this.filteredArray = this.filteredArray.filter(ele => ele[element].includes(this.tableFilteredObject[element]) )
    })
  }

  /* onModalScrollDown (ev) {
   let pg = Math.ceil(this.allDetailsArr.length / 10000000);
   this.getDetails(pg+1, 10000000, this.currentFilters);
  } */

  getDetails(offset = 1, limit = 10000000, filters = {}) { 
    this.loading = true;
    let params = {
      pageNo: offset,
      pageSize: limit,
      ...filters
    }

    this.http.getData(this.constants.otSchedularUrl, params ).then((res:any) => {
      // this.loading = true;
    this.allDetailsArr = this.allDetailsArr.concat(res.result);
    this.allDetailsArr.sort((a, b) =>{
      let keyA = new Date(a.appointmentDate),
        keyB = new Date(b.appointmentDate);
      // Compare the 2 dates
      if (keyA < keyB) return -1;
      if (keyA > keyB) return 1;
      return 0;
    });

    this.allDetailsArr = this.allDetailsArr.map((e) => {
      Object.keys(e).map(ele => {
        if(e[ele] === 'On-Time') {
          e[ele] = `<span class="green-class">${e[ele]}</span>`
        } 
        if(e[ele] === 'Delayed') {
          e[ele] = `<span class="red-class">${e[ele]}</span>`
        } 
      })
      return e;
    })
    this.OTMaxCount = res.totalCount;
    this.maxPageNumber = Math.ceil(this.OTMaxCount / limit);
    this.allDetailsArr.map(x => {
      return Object.assign({}, x);
    });
    this.filteredArray = this.allDetailsArr;

    Object.keys(this.tableFilteredObject).map(element => {
      if(this.tableFilteredObject[element]) this.onTableFilterSelect( this.tableFilteredObject[element] , element)
    })
    this.loading = false;
   })
    .catch((err) => {
      this.loading = false;
    });
  }  

  getCount(key) {
    let count = 0
    this.allDetailsArr.map(e => {
      if(e[key] && e[key].includes('On-Time')){
        count +=1;
      };
    })
    return count;
  }

  getDocCityCategoryData() {
    this.http.getDocCityCategory(this.constants.docCityCategory).then((res:any) => {
      this.data = res.result;
      this.city = this.data.cityAndRegionList;
      this.allCitiesArr = Array.from(new Set(this.city.map(ele => ele.cityName).sort()));

      this.category = this.data.diseaseList.sort();

      this.docName = this.data.doctorList;
      this.docNameArr = this.docName.map(ele => ele.doctorName).sort();
    })
    .catch((err) => {
      this.loading = false;
    });
  }

  onDateSave() {
    if (this.selectedDate && this.selectedDate.startDate && this.selectedDate.endDate ) {
      let sD = this.selectedDate.startDate.format('yyyy-MM-DD');
      let eD = this.selectedDate.endDate.format('yyyy-MM-DD');
      if (sD === eD) {
        this.appointmentDate = sD;
      }
      else {
        this.appointmentDate = `${sD},${eD}`;
      }
    }
  }

  filterResults() {
    this.allDetailsArr=[];
    if(this.selectedCities){
      this.currentFilters.city = this.selectedCities
    }
    if(this.selectedCategories){
      this.currentFilters.category = this.selectedCategories
    }
    if(this.selectedDoctors){
      this.currentFilters.doctorName = this.selectedDoctors
    }
    if(this.appointmentDate) {
      this.currentFilters.appointmentDate = this.appointmentDate
    }
    this.pageNumber = 1;  
    this.currentDate = "Select Date"
    this.getDetails(this.pageNumber, 10000000, this.currentFilters);
  }

  clearFilters() {
    this.selectedCategories = [];
    this.selectedCities = [];
    this.selectedDoctors = [];
    this.currentFilters = {};
    this.selectedDate = null;
    this.allDetailsArr=[];
    this.currentDate = moment().format('DD-MM-YYYY');
     
    Object.keys(this.tableFilteredObject).map(element => {
      this.tableFilteredObject[element] = false;
    })
    
    this.filteredArray= this.allDetailsArr;

    this.getDetails();
  }

  exportexcel(): void
  {
    let table_elt = document.getElementById("excel-table");
    let workbook = XLSX.utils.table_to_book(table_elt);
    let ws = workbook.Sheets["Sheet1"];

    ws.I1.v = "Appointment Creation Status";
    ws.J1.v = "OT Room Confirmation";
    ws.K1.v = "Staff Availability";
    ws.L1.v = "Patient Arrival";
    ws.M1.v = "Admission To Room";
    ws.N1.v = "Pre-Auth Request Received";
    ws.O1.v = "Pre-Auth Sent";
    ws.P1.v = "Investigation Report";
    ws.Q1.v = "OT Start";
    ws.R1.v = "OT End";
    ws.S1.v = "OT Notes Upload";
    ws.T1.v = "PDS";
    ws.U1.v = "Bill Breakup";
    ws.V1.v = "FDS";
    ws.W1.v = "Final Bill";
    XLSX.writeFile(workbook, "Appointments.xlsx");
  }

  reloadCurrentPage() {
    window.location.reload();
  }
}
