import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppComponent } from './app.component';
import { PageNotFoundComponent } from '../components/page-not-found/page-not-found.component';
import { LayoutComponent } from './layout/layout.component';
import { PatientTrackerComponent } from './patient-tracker/patient-tracker.component';

const routes: Routes = [
  { path: '', component: LayoutComponent },
  { path: 'ptracker/:id', component: PatientTrackerComponent },
  // { path: '**', component: PageNotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
