import { HttpClient, HttpHeaders, HttpParams, } from '@angular/common/http';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HttpServiceService {

  header: any;
  constructor(private http: HttpClient, public router: Router) { }

  /**
  *this method posts data to a server
  *@param url - the url to post data to
  *@param data - the data to send to the url
  */

  getData(url:string, params: {}) { 
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({ 
        'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8',
        'productName':'Live-Tracker'
      })
      const options = {
        headers: headers,
        params: params
     };
     this.http.get(url, options).subscribe(
      res => resolve(res),
     )
    })
  }

  getDocCityCategory(url:string) {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({ 
        'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8',
        'productName':'Live-Tracker'
      })
      const options = {
        headers: headers,
     };
     this.http.get(url, options).subscribe(
      res => resolve(res),
     )
    })
  }

  getData2(url:string, params: {}) { 
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({ 
        'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8',
        'productName':'Live-Tracker'
      })
      const options = {
        headers: headers,
        params: params
     };
     this.http.get(url, options).subscribe(
      res => resolve(res),
     )
    })
  }
}

